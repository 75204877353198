import React from 'react';

import useTrans from '../hooks/useTrans';

function NotFound() {
  const { trans } = useTrans();

  return <div className="NotFound">{trans('navigation.not_found')}</div>;
}

export default NotFound;
