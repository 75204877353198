import React from 'react';
import PropTypes from 'prop-types';

function LogoIcon({ className, type }) {
  // const randomNumber = Math.random();
  switch (type) {
    case 'light':
      return (
        <svg
          className={className}
          id="Layer_2"
          viewBox="0 0 103.54 26.46"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id="linear-gradient"
              x1="1.57"
              x2="23.95"
              y1="26.25"
              y2=".3"
            >
              <stop offset="0" stopColor="#ed7218" />
              <stop offset="1" stopColor="#f29111" />
            </linearGradient>
          </defs>
          <g id="Layer_1-2">
            <polygon
              className="cls-2"
              fill="#ed7218"
              points="0 0 0 26.46 25.61 26.46 25.61 14.52 13.99 14.52 13.99 19.66 11.62 19.66 11.62 6.77 13.99 6.77 13.99 11.93 25.61 11.93 25.61 0 0 0"
            />
            <polygon
              className="cls-1"
              points="0 0 0 26.46 25.61 26.46 25.61 14.52 13.99 14.52 13.99 19.66 11.62 19.66 11.62 6.77 13.99 6.77 13.99 11.94 25.61 11.94 25.61 0 0 0"
            />
            <path
              className="cls-3"
              d="M52.51,4.07c-.17.66-.32,1.34-.43,2.01h-.03c-.14-.69-.32-1.38-.5-1.97l-.62-2.22h-1.93l2.04,5.74v3.56h1.81v-3.63l2.08-5.67h-1.88l-.55,2.18Z"
            />
            <path
              className="cls-3"
              d="M89.74,7.93c0,1.4-.37,1.78-.9,1.78-.48,0-.88-.41-.88-1.78V1.89h-1.8v5.74c0,2.32.76,3.67,2.7,3.67,1.78,0,2.69-1.3,2.69-3.68V1.89h-1.81v6.04Z"
            />
            <path
              className="cls-3"
              d="M101.86,5.79c-.99-.61-1.27-.9-1.27-1.46,0-.48.37-.92,1.15-.92.51,0,.9.15,1.17.28l.29-1.56c-.26-.15-.84-.33-1.46-.33-1.93,0-2.92,1.24-2.92,2.73,0,.84.48,1.82,1.75,2.59.79.51,1.18.82,1.18,1.5,0,.53-.51.97-1.35.97-.51,0-1.05-.1-1.43-.26l-.26,1.56c.32.18,1.03.37,1.78.37,1.81,0,3.06-1.12,3.06-2.81,0-.98-.44-1.92-1.68-2.66Z"
            />
            <path
              className="cls-3"
              d="M42.21,3.4h0c.45-.02.82,0,1.04.01l.21-1.57s-.61-.04-1.25-.04h0c-1.81,0-3.75,1.37-3.75,4.87,0,2.64,1.13,4.59,3.51,4.59h0s0,0,0,0c0,0,0,0,0,0,0,0,0,0,.01,0,.85,0,1.41-.09,1.41-.09l-.17-1.53c-.26,0-.73.02-.9.02-1.1,0-1.94-.87-1.94-3.09s.86-3.12,1.83-3.18Z"
            />
            <path
              className="cls-3"
              d="M66.31,4.94c0-2.26-1.2-3.09-3.18-3.09-1.1,0-1.91.1-1.91.1v9.23h1.7v-3.09c.24,0,.54-.02.77-.05l1.14,3.14h1.78l-1.39-3.76c.67-.51,1.1-1.32,1.1-2.49ZM62.92,3.71c1.02-.09,1.62.29,1.62,1.29,0,.93-.68,1.36-1.62,1.35v-2.64Z"
            />
            <path
              className="cls-3"
              d="M78.81,4.94c0-2.26-1.2-3.09-3.18-3.09-1.1,0-1.91.1-1.91.1v9.23h1.7v-3.09c.24,0,.54-.02.77-.05l1.14,3.14h1.78l-1.39-3.76c.67-.51,1.1-1.32,1.1-2.49ZM75.42,3.71c1.02-.09,1.62.29,1.62,1.29,0,.93-.68,1.36-1.62,1.35v-2.64Z"
            />
            <path
              className="cls-3"
              d="M39.74,24.57h-.61v-7.5h4.13v.55h-3.52v3.09h3.32v.55h-3.32v3.32Z"
            />
            <path
              className="cls-3"
              d="M52.08,20.81c0,.76-.13,1.44-.38,2.02-.25.58-.63,1.03-1.13,1.36-.5.33-1.12.49-1.86.49s-1.38-.16-1.88-.49c-.5-.33-.88-.78-1.12-1.36-.25-.58-.37-1.26-.37-2.02s.13-1.43.38-2.01c.26-.57.64-1.02,1.14-1.35.51-.33,1.14-.49,1.88-.49s1.33.16,1.83.47.87.75,1.13,1.33c.25.57.38,1.26.38,2.06ZM45.97,20.8c0,.67.1,1.25.29,1.75.2.5.5.89.9,1.17.41.28.92.42,1.54.42s1.15-.14,1.55-.41c.4-.27.7-.66.89-1.16.19-.5.29-1.08.29-1.75,0-1.04-.23-1.85-.68-2.44-.45-.58-1.13-.87-2.02-.87-.62,0-1.14.14-1.55.41-.41.28-.72.66-.92,1.16-.2.49-.3,1.07-.3,1.73Z"
            />
            <path
              className="cls-3"
              d="M56.75,17.07c.9,0,1.57.16,2.02.47.45.31.68.84.68,1.59,0,.57-.15,1.02-.46,1.35-.3.33-.68.56-1.14.69l2.06,3.4h-.72l-1.91-3.23h-1.79v3.23h-.61v-7.5h1.87ZM56.7,17.61h-1.21v3.2h1.42c.61,0,1.08-.14,1.41-.43.33-.28.5-.69.5-1.23,0-.57-.17-.97-.52-1.2-.35-.23-.88-.34-1.6-.34Z"
            />
            <path
              className="cls-3"
              d="M66.44,24.57h-4.12v-7.5h4.12v.55h-3.51v2.74h3.31v.55h-3.31v3.12h3.51v.55Z"
            />
            <path
              className="cls-3"
              d="M74.28,24.57h-.69l-2.17-3.46-2.21,3.46h-.66l2.51-3.9-2.3-3.6h.68l2,3.17,2-3.17h.66l-2.32,3.59,2.5,3.91Z"
            />
          </g>
        </svg>
      );
    case 'dark':
    default:
      return (
        <svg
          className={className}
          id="Layer_2"
          viewBox="0 0 103.54 26.46"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id="linear-gradient"
              x1="1.57"
              x2="23.95"
              y1="26.25"
              y2=".3"
            >
              <stop offset="0" stopColor="#ed7218" />
              <stop offset="1" stopColor="#f29111" />
            </linearGradient>
          </defs>
          <g id="Layer_1-2">
            <polygon
              className="cls-3dark"
              points="0 0 0 26.46 25.61 26.46 25.61 14.52 13.99 14.52 13.99 19.66 11.62 19.66 11.62 6.77 13.99 6.77 13.99 11.93 25.61 11.93 25.61 0 0 0"
            />
            <polygon
              className="cls-1dark"
              points="0 0 0 26.46 25.61 26.46 25.61 14.52 13.99 14.52 13.99 19.66 11.62 19.66 11.62 6.77 13.99 6.77 13.99 11.94 25.61 11.94 25.61 0 0 0"
            />
            <path
              className="cls-2dark"
              d="M52.51,4.07c-.17.66-.32,1.34-.43,2.01h-.03c-.14-.69-.32-1.38-.5-1.97l-.62-2.22h-1.93l2.04,5.74v3.56h1.81v-3.63l2.08-5.67h-1.88l-.55,2.18Z"
            />
            <path
              className="cls-2dark"
              d="M89.74,7.93c0,1.4-.37,1.78-.9,1.78-.48,0-.88-.41-.88-1.78V1.89h-1.8v5.74c0,2.32.76,3.67,2.7,3.67,1.78,0,2.69-1.3,2.69-3.68V1.89h-1.81v6.04Z"
            />
            <path
              className="cls-2dark"
              d="M101.86,5.79c-.99-.61-1.27-.9-1.27-1.46,0-.48.37-.92,1.15-.92.51,0,.9.15,1.17.28l.29-1.56c-.26-.15-.84-.33-1.46-.33-1.93,0-2.92,1.24-2.92,2.73,0,.84.48,1.82,1.75,2.59.79.51,1.18.82,1.18,1.5,0,.53-.51.97-1.35.97-.51,0-1.05-.1-1.43-.26l-.26,1.56c.32.18,1.03.37,1.78.37,1.81,0,3.06-1.12,3.06-2.81,0-.98-.44-1.92-1.68-2.66Z"
            />
            <path
              className="cls-2dark"
              d="M42.21,3.4h0c.45-.02.82,0,1.04.01l.21-1.57s-.61-.04-1.25-.04h0c-1.81,0-3.75,1.37-3.75,4.87,0,2.64,1.13,4.59,3.51,4.59h0s0,0,0,0c0,0,0,0,0,0,0,0,0,0,.01,0,.85,0,1.41-.09,1.41-.09l-.17-1.53c-.26,0-.73.02-.9.02-1.1,0-1.94-.87-1.94-3.09s.86-3.12,1.83-3.18Z"
            />
            <path
              className="cls-2dark"
              d="M66.31,4.94c0-2.26-1.2-3.09-3.18-3.09-1.1,0-1.91.1-1.91.1v9.23h1.7v-3.09c.24,0,.54-.02.77-.05l1.14,3.14h1.78l-1.39-3.76c.67-.51,1.1-1.32,1.1-2.49ZM62.92,3.71c1.02-.09,1.62.29,1.62,1.29,0,.93-.68,1.36-1.62,1.35v-2.64Z"
            />
            <path
              className="cls-2dark"
              d="M78.81,4.94c0-2.26-1.2-3.09-3.18-3.09-1.1,0-1.91.1-1.91.1v9.23h1.7v-3.09c.24,0,.54-.02.77-.05l1.14,3.14h1.78l-1.39-3.76c.67-.51,1.1-1.32,1.1-2.49ZM75.42,3.71c1.02-.09,1.62.29,1.62,1.29,0,.93-.68,1.36-1.62,1.35v-2.64Z"
            />
            <path
              className="cls-2dark"
              d="M39.74,24.57h-.61v-7.5h4.13v.55h-3.52v3.09h3.32v.55h-3.32v3.32Z"
            />
            <path
              className="cls-2dark"
              d="M52.08,20.81c0,.76-.13,1.44-.38,2.02-.25.58-.63,1.03-1.13,1.36-.5.33-1.12.49-1.86.49s-1.38-.16-1.88-.49c-.5-.33-.88-.78-1.12-1.36-.25-.58-.37-1.26-.37-2.02s.13-1.43.38-2.01c.26-.57.64-1.02,1.14-1.35.51-.33,1.14-.49,1.88-.49s1.33.16,1.83.47.87.75,1.13,1.33c.25.57.38,1.26.38,2.06ZM45.97,20.8c0,.67.1,1.25.29,1.75.2.5.5.89.9,1.17.41.28.92.42,1.54.42s1.15-.14,1.55-.41c.4-.27.7-.66.89-1.16.19-.5.29-1.08.29-1.75,0-1.04-.23-1.85-.68-2.44-.45-.58-1.13-.87-2.02-.87-.62,0-1.14.14-1.55.41-.41.28-.72.66-.92,1.16-.2.49-.3,1.07-.3,1.73Z"
            />
            <path
              className="cls-2dark"
              d="M56.75,17.07c.9,0,1.57.16,2.02.47.45.31.68.84.68,1.59,0,.57-.15,1.02-.46,1.35-.3.33-.68.56-1.14.69l2.06,3.4h-.72l-1.91-3.23h-1.79v3.23h-.61v-7.5h1.87ZM56.7,17.61h-1.21v3.2h1.42c.61,0,1.08-.14,1.41-.43.33-.28.5-.69.5-1.23,0-.57-.17-.97-.52-1.2-.35-.23-.88-.34-1.6-.34Z"
            />
            <path
              className="cls-2dark"
              d="M66.44,24.57h-4.12v-7.5h4.12v.55h-3.51v2.74h3.31v.55h-3.31v3.12h3.51v.55Z"
            />
            <path
              className="cls-2dark"
              d="M74.28,24.57h-.69l-2.17-3.46-2.21,3.46h-.66l2.51-3.9-2.3-3.6h.68l2,3.17,2-3.17h.66l-2.32,3.59,2.5,3.91Z"
            />
          </g>
        </svg>
      );
  }
}

LogoIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['dark', 'light']),
};
LogoIcon.defaultProps = {
  className: '',
  type: 'dark',
};

export default LogoIcon;
