import React, { useCallback } from 'react';
import Toast from '../atoms/Toast';
import ToastRegion from '../atoms/ToastRegion';
import { useToastAtom } from '../hooks/useToast';

function ToastContextProvider() {
  const [toasts, setToasts] = useToastAtom();

  const removeToast = useCallback(
    (toast) => {
      setToasts([...toasts.filter((t) => t.id !== toast.id)]);
    },
    [setToasts, toasts],
  );

  return (
    <ToastRegion>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          onRemove={() => removeToast(toast)}
          toast={toast}
        />
      ))}
    </ToastRegion>
  );
}

export default ToastContextProvider;
