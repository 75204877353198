import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import useLocale from '../hooks/useLocale';

export const RouteContext = createContext();

function RouteContextProvider({ children }) {
  const [routes] = useState(window.preload.routes);

  const [baseUrl] = useState(window.preload.base_url);

  const { locale, locales } = useLocale();

  const route = useCallback(
    (route, parameters = null, full = false, forceLocale = null) => {
      const localeToSearch = forceLocale || locale;

      if (
        routes[route] &&
        routes[route][localeToSearch] &&
        routes[route][localeToSearch].url
      ) {
        let href = routes[route][localeToSearch].url;

        href = href.replace('{', ':').replace('}', '');

        if (parameters) {
          if (typeof parameters !== 'object') {
            throw new Error('Parameters has to be object');
          }

          Object.keys(parameters).forEach((key) => {
            href = href.replace(':' + key, String(parameters[key]));
          });
        }

        return full ? baseUrl + href : href;
      }

      throw new Error('Invalid route :' + route);
    },
    [locale, routes, baseUrl],
  );

  const allRoutes = useCallback(
    (path, parameters = null, full = false) => {
      return locales.map((locale) => route(path, parameters, full, locale));
    },
    [route, locales],
  );

  return (
    <RouteContext.Provider value={{ route, allRoutes }}>
      {children}
    </RouteContext.Provider>
  );
}

RouteContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

RouteContextProvider.defaultProps = {};

export default RouteContextProvider;
