import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function AsideItem({ icon, to, label, exact, iconActive, onClick }) {
  /* eslint-disable react/forbid-component-props */
  return (
    <NavLink
      activeClassName="NavLink NavLink--activated"
      className="NavLink"
      exact={exact}
      onClick={onClick}
      to={to}
    >
      <span aria-hidden="true">
        <img alt={to} className="NavLink__icon" src={icon} />
      </span>

      <span aria-hidden="true">
        <img alt={to} className="NavLink__icon--active" src={iconActive} />
      </span>

      <span className="NavLink__text">{label}</span>
    </NavLink>
  );
  /* eslint-enable react/forbid-component-props */
}

AsideItem.propTypes = {
  exact: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  iconActive: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
};

AsideItem.defaultProps = {
  exact: false,
};

export default AsideItem;
