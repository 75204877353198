import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LightDarkToggle } from 'react-light-dark-toggle';

import useRoutes from '../hooks/useRoutes';
import useAuth from '../hooks/useAuth';
import UserIcon from '../atoms/UserIcon';
import UserMenu from '../atoms/UserMenu';
import LogoIcon from '../icons/LogoIcon';
import useTheme from '../hooks/useTheme';
import VideosButton from '../atoms/VideosButton';

function Header({ hidden }) {
  const { route } = useRoutes();
  const { changeTheme, theme } = useTheme();

  const { account } = useAuth();

  /* eslint-disable react/forbid-component-props */
  return (
    <header
      className={['Header', !hidden && 'Header--visible']
        .filter((el) => el)
        .join(' ')}
      hidden={hidden}
    >
      <div className="top-bar">
        <div className="top-bar-left">
          <Link className="Header__logo" to={route('index')}>
            <LogoIcon className="Header__logo-image" type={theme} />
          </Link>
        </div>

        <div className="top-bar-right">
          <VideosButton />
          <LightDarkToggle
            hideMoonTransform="translateY(-4em) translateX(-16px)"
            hideSunTransform="translateY(-4em) translateX(-29px)"
            isLight={theme === 'light'}
            lightBackgroundColor="#ffffff"
            onToggle={() => changeTheme(theme === 'light' ? 'dark' : 'light')}
            showMoonTransform="translateY(0) translateX(-16px)"
            showSunTransform="translateY(0) translateX(-29px)"
            style={{
              fontSize: '16px',
              width: '100px',
            }}
          />
          {account ? (
            <UserIcon name={account.client_description} trailing>
              <UserMenu />
            </UserIcon>
          ) : null}
        </div>
      </div>
    </header>
  );
  /* eslint-enable react/forbid-component-props */
}

Header.propTypes = {
  hidden: PropTypes.bool.isRequired,
};

Header.defaultProps = {};

export default Header;
