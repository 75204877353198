import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TOAST_DELAY_HIDE,
  TOAST_DELAY_SHOW,
  TOAST_TYPE,
} from '../hooks/useToast';
import checkedIcon from '../../assets/svg/check-circle.svg';
import closeIcon from '../../assets/svg/close.svg';

function Toast({ toast, onRemove }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeoutToShow = setTimeout(() => setVisible(true), TOAST_DELAY_SHOW);
    const timeoutToRemove = setTimeout(
      () => onRemove(),
      toast.delayHide || TOAST_DELAY_HIDE,
    );

    return () => {
      timeoutToShow && clearTimeout(timeoutToShow);
      timeoutToRemove && clearTimeout(timeoutToRemove);
    };
  }, [onRemove, toast.delayHide]);

  if (!visible) {
    return null;
  }

  return (
    <div className={`Toast Toast--type-${toast.type}`}>
      <div className="Toast__close">
        <img
          alt="Icon"
          aria-hidden="true"
          className="Toast__icon"
          onClick={() => onRemove()}
          src={closeIcon}
        />
      </div>
      <div className="Toast__header">
        {toast.type === 'success' && (
          <img alt="Icon" className="Toast__icon" src={checkedIcon} />
        )}
        <span className="Toast__title">{toast.title}</span>
      </div>
      {toast.description ? (
        <div className="Toast__content">{toast.description}</div>
      ) : null}
    </div>
  );
}

Toast.propTypes = {
  onRemove: PropTypes.func.isRequired,
  toast: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(TOAST_TYPE)).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    delayHide: PropTypes.number,
  }).isRequired,
};

export default Toast;
