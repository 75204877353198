import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { generateString } from '../helpers/string';

export const TOAST_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
};

export const TOAST_DELAY_SHOW = 100;
export const TOAST_DELAY_HIDE = 5000;

const toastAtom = atom([]);

export function useToastAtom() {
  return useAtom(toastAtom);
}

function useToast() {
  const [toasts, setToasts] = useToastAtom();
  const createToast = useCallback(
    (toast) => setToasts([{ ...toast, id: generateString() }, ...toasts]),
    [setToasts, toasts],
  );

  return { createToast };
}

export default useToast;
