import useLocale from './useLocale';
import useAuth from './useAuth';
import { useCallback } from 'react';

function useFetch(resource) {
  const { locale } = useLocale();

  const { token, accountId } = useAuth();

  return useCallback(
    (...rest) => {
      return resource(locale, token, accountId, ...rest);
    },
    [resource, locale, token, accountId],
  );
}

export default useFetch;
