import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import {
  amlMessageIndex,
  createResource,
  bussinessHoursIndex,
  ratesIndex,
  bankAccountsIndex,
  balanciesIndex,
  clientCurrenciesIndex,
  paymentTemplatesIndex,
  paymentTitlesIndex,
  paymentPurposesIndex,
  tradeValidationMessagesIndex,
  longTermRatesIndex,
  countriesIndex,
  holidaysIndex,
  allPaymentTempatesIndex,
  graphShow,
} from '../fetch';
import useFetch from '../hooks/useFetch';

const ONE_HOUR = 3600000;
const TEN_MINUTES = 600000;

const bussinessHoursResource = createResource(bussinessHoursIndex, ONE_HOUR);
const ratesResource = createResource(ratesIndex, TEN_MINUTES);
const longTermRatesResource = createResource(longTermRatesIndex, ONE_HOUR);
const bankAccountsResource = createResource(bankAccountsIndex);
const balanciesResource = createResource(balanciesIndex);
const clientCurrenciesResource = createResource(clientCurrenciesIndex);
const paymentTemplatesResource = createResource(paymentTemplatesIndex);
const paymentTitlesResource = createResource(paymentTitlesIndex, ONE_HOUR);
const paymentPurposesResource = createResource(paymentPurposesIndex, ONE_HOUR);
const tradeValidationMessagesResource = createResource(
  tradeValidationMessagesIndex,
  ONE_HOUR,
);
const countriesResource = createResource(countriesIndex, ONE_HOUR);
const holidaysResource = createResource(holidaysIndex, ONE_HOUR);
const allPaymentTemplatesResource = createResource(allPaymentTempatesIndex);
const graphResource = createResource(graphShow, ONE_HOUR);
const amlMessageResource = createResource(amlMessageIndex, TEN_MINUTES);

export const StaticContext = createContext();

function StaticContextProvider({ children }) {
  const bussinessHours = useFetch(bussinessHoursResource);

  const rates = useFetch(ratesResource);

  const longTermRates = useFetch(longTermRatesResource);

  const bankAccounts = useFetch(bankAccountsResource);

  const balancies = useFetch(balanciesResource);

  const currencies = useFetch(clientCurrenciesResource);

  const paymentTemplates = useFetch(paymentTemplatesResource);

  const paymentTitles = useFetch(paymentTitlesResource);

  const paymentPurposes = useFetch(paymentPurposesResource);

  const tradeValidationMessages = useFetch(tradeValidationMessagesResource);

  const countries = useFetch(countriesResource);

  const holidays = useFetch(holidaysResource);

  const allPaymentTemplates = useFetch(allPaymentTemplatesResource);

  const graph = useFetch(graphResource);

  const amlMessage = useFetch(amlMessageResource);

  return (
    <StaticContext.Provider
      value={{
        bussinessHours,
        rates,
        bankAccounts,
        balancies,
        currencies,
        paymentTemplates,
        paymentTitles,
        paymentPurposes,
        tradeValidationMessages,
        longTermRates,
        countries,
        holidays,
        allPaymentTemplates,
        graph,
        amlMessage,
      }}
    >
      {children}
    </StaticContext.Provider>
  );
}

StaticContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

StaticContextProvider.defaultProps = {};

export default StaticContextProvider;
