import React, { useState } from 'react';

import useTrans from '../hooks/useTrans';
import useAuth from '../hooks/useAuth';

import UserIcon from './UserIcon';

function UserMenu() {
  const [page, setPage] = useState(0);

  const { trans } = useTrans();

  const { accounts, setAccount, logout } = useAuth();

  return (
    <nav className="UserMenu">
      <div className="UserMenu__inner">
        {page === 0 && (
          <>
            <div
              className="UserMenu__item UserMenu__item--paginator UserMenu__item--grow"
              onClick={() => {
                setPage(1);
              }}
              onKeyDown={() => {}}
              role="button"
              tabIndex="0"
            >
              <div>{trans('header.menu.change_account')}</div>

              <div>
                <svg
                  height="18px"
                  style={{ marginLeft: '0.5rem' }}
                  viewBox="0 0 24 24"
                  width="18px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0z" fill="none" />

                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                </svg>
              </div>
            </div>
            <div
              className="UserMenu__item"
              onClick={logout}
              onKeyDown={() => {}}
              role="button"
              style={{ opacity: 0.6 }}
              tabIndex="0"
            >
              {trans('header.menu.logout')}
            </div>
          </>
        )}

        {page === 1 && (
          <>
            <div
              className="UserMenu__item UserMenu__item--paginator"
              onClick={() => {
                setPage(0);
              }}
              onKeyDown={() => {}}
              role="button"
              tabIndex="0"
            >
              <div>
                <svg
                  fill="black"
                  height="18px"
                  style={{ marginRight: '0.5rem' }}
                  viewBox="0 0 24 24"
                  width="18px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0z" fill="none" />

                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
              </div>

              <div>{trans('header.menu.back')}</div>
            </div>

            {accounts
              ? accounts.map((account) => {
                  return (
                    <div
                      className="UserMenu__item"
                      key={account.account_id}
                      onClick={() => {
                        setAccount(account);
                      }}
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex="0"
                    >
                      <UserIcon name={account.client_description} />
                    </div>
                  );
                })
              : null}
          </>
        )}
      </div>
    </nav>
  );
}

export default UserMenu;
