import React, {
  createContext,
  useReducer,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

export const SET_USER = Symbol();
export const SET_TOKEN = Symbol();
export const SET_CLIENT = Symbol();
export const SET_ACCOUNT = Symbol();
export const SET_ACCOUNTS = Symbol();
export const SET_FRESH_LOGIN = Symbol();
export const INIT = Symbol();

export const AuthContext = createContext();

import { internalFetch } from '../api';

import useRoutes from '../hooks/useRoutes';
import useLocale from '../hooks/useLocale';

function init(token = undefined) {
  return {
    client: null,
    user: null,
    token: token,
    account: null,
    accounts: [],
    freshLogin: null,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: { ...action.data } };
    case SET_TOKEN:
      return { ...state, token: action.data };
    case SET_CLIENT:
      return { ...state, client: action.data };
    case SET_ACCOUNT:
      return { ...state, account: { ...action.data } };
    case SET_ACCOUNTS:
      return { ...state, accounts: [...action.data] };
    case SET_FRESH_LOGIN:
      return { ...state, freshLogin: action.data };
    case INIT:
      return init(null);
    default:
      throw new Error();
  }
}

function AuthContextProvider({ children }) {
  const history = useHistory();

  const { route } = useRoutes();

  const { locale } = useLocale();

  const [state, dispatch] = useReducer(reducer, null, init);

  const setUser = useCallback(
    (payload) => {
      dispatch({ type: SET_USER, data: payload });
    },
    [dispatch],
  );

  const setToken = useCallback(
    (payload) => {
      dispatch({ type: SET_TOKEN, data: payload });
    },
    [dispatch],
  );

  const setClient = useCallback(
    (payload) => {
      dispatch({ type: SET_CLIENT, data: payload });
    },
    [dispatch],
  );

  const setAccount = useCallback(
    (payload) => {
      dispatch({ type: SET_ACCOUNT, data: payload });
    },
    [dispatch],
  );

  const setAccounts = useCallback(
    (payload) => {
      dispatch({ type: SET_ACCOUNTS, data: payload });
    },
    [dispatch],
  );

  const setFreshLogin = useCallback(
    (payload) => {
      dispatch({ type: SET_FRESH_LOGIN, data: payload });
    },
    [dispatch],
  );

  const token = useMemo(() => {
    return state.token;
  }, [state]);

  const clear = useCallback(async () => {
    if (token) {
      await internalFetch(null, '/api/csrf', 'GET', locale);
      await internalFetch(null, '/api/v1/auth/logout', 'DELETE', locale, token);

      localStorage.clear();
      sessionStorage.clear();

      dispatch({ type: INIT });

      window.location.reload();
    }
  }, [dispatch, token, locale]);

  const logout = useCallback(() => {
    if (route('auth.logout') != history.location.pathname) {
      history.push(route('auth.logout'));
    }
  }, [history, route]);

  const user = useMemo(() => {
    return state.user;
  }, [state]);

  const client = useMemo(() => {
    return state.client;
  }, [state]);

  const account = useMemo(() => {
    return state.account;
  }, [state]);

  const accountId = useMemo(() => {
    return (account || {}).account_id;
  }, [account]);

  const accounts = useMemo(() => {
    return state.accounts;
  }, [state]);

  const freshLogin = useMemo(() => {
    return state.freshLogin;
  }, [state]);

  useEffect(() => {
    async function fetchData() {
      await internalFetch(null, '/api/csrf', 'GET', locale);

      const status = await internalFetch(
        null,
        '/api/v1/auth/me',
        'GET',
        locale,
      );

      if (status.status.ok) {
        setToken(true);
      } else {
        setToken(null);
      }
    }

    fetchData();
  }, [locale, setToken]);

  if (token === undefined) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        client,
        account,
        accountId,
        accounts,
        freshLogin,
        setUser,
        setToken,
        setClient,
        setAccount,
        setAccounts,
        setFreshLogin,
        clear,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthContextProvider.defaultProps = {};

export default AuthContextProvider;
