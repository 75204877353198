import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import AppLayout from './layouts/AppLayout';

import AuthRoute from './routes/AuthRoute';
import GuestRoute from './routes/GuestRoute';

import Loading from './routes/Loading';
import NotFound from './routes/NotFound';

import useRoutes from './hooks/useRoutes';

const AuthLogin = lazy(() => import('./routes/auth/AuthLogin'));
const AuthForgot = lazy(() => import('./routes/auth/AuthForgot'));
const AuthReset = lazy(() => import('./routes/auth/AuthReset'));
const AuthLogout = lazy(() => import('./routes/auth/AuthLogout'));
const AuthRestoration = lazy(() => import('./routes/auth/AuthRestoration'));
const VideosIndex = lazy(() => import('./routes/videos/VideosIndex'));

const AccountsIndex = lazy(() => import('./routes/accounts/AccountsIndex'));
const HistoryIndex = lazy(() => import('./routes/history/HistoryIndex'));
const NotificationsIndex = lazy(() =>
  import('./routes/notifications/NotificationsIndex'),
);
const InstructionsIndex = lazy(() =>
  import('./routes/instructions/InstructionsIndex'),
);
const TemplatesIndex = lazy(() => import('./routes/templates/TemplatesIndex'));
const NewTemplate = lazy(() => import('./routes/templates/NewTemplate'));
const EditTemplate = lazy(() => import('./routes/templates/EditTemplate'));
const BankAccountsIndex = lazy(() =>
  import('./routes/bankaccounts/BankAccountsIndex'),
);
const NewBankAccount = lazy(() =>
  import('./routes/bankaccounts/NewBankAccount'),
);

const SettingsIndex = lazy(() => import('./routes/settings/SettingsIndex'));
const InstructionNoWithdrawal = lazy(() =>
  import('./routes/instructions/InstructionNoWithdrawal'),
);
const InstructionPayment = lazy(() =>
  import('./routes/instructions/InstructionPayment'),
);
const InstructionTransaction = lazy(() =>
  import('./routes/instructions/InstructionTransaction'),
);

const SellCurrency = lazy(() => import('./routes/accounts/SellCurrency'));
const BuyCurrency = lazy(() => import('./routes/accounts/BuyCurrency'));
const Deposit = lazy(() => import('./routes/accounts/Deposit'));
const Withdrawal = lazy(() => import('./routes/accounts/Withdrawal'));
const Payment = lazy(() => import('./routes/accounts/Payment'));
const Transfer = lazy(() => import('./routes/accounts/Transfer'));
const NoWithdrawal = lazy(() => import('./routes/accounts/NoWithdrawal'));
const BuyPayment = lazy(() => import('./routes/accounts/BuyPayment'));
const BuyTransfer = lazy(() => import('./routes/accounts/BuyTransfer'));
const BuyNoWithdrawal = lazy(() => import('./routes/accounts/BuyNoWithdrawal'));
const NewNotification = lazy(() =>
  import('./routes/notifications/NewNotification'),
);

import ErrorBoundary from './routes/ErrorBoundary';

function App() {
  const { allRoutes: route } = useRoutes();

  return (
    <AppLayout>
      <Switch>
        <GuestRoute exact path={route('auth.login')}>
          <ErrorBoundary>
            <AuthLogin />
          </ErrorBoundary>
        </GuestRoute>

        <GuestRoute exact path={route('auth.forgot')}>
          <ErrorBoundary>
            <AuthForgot />
          </ErrorBoundary>
        </GuestRoute>

        <GuestRoute exact path={route('auth.reset')}>
          <ErrorBoundary>
            <AuthReset />
          </ErrorBoundary>
        </GuestRoute>

        <GuestRoute exact path={route('auth.restoration')}>
          <ErrorBoundary>
            <AuthRestoration />
          </ErrorBoundary>
        </GuestRoute>

        <Route exact path={route('auth.logout')}>
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <AuthLogout />
            </Suspense>
          </ErrorBoundary>
        </Route>

        <AuthRoute exact path={route('index')}>
          <ErrorBoundary>
            <AccountsIndex />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.buy_payment')}>
          <ErrorBoundary>
            <BuyPayment />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.buy_transfer')}>
          <ErrorBoundary>
            <BuyTransfer />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.buy_no_withdrawal')}>
          <ErrorBoundary>
            <BuyNoWithdrawal />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.withdrawal')}>
          <ErrorBoundary>
            <Withdrawal />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.deposit')}>
          <ErrorBoundary>
            <Deposit />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.no_withdrawal')}>
          <ErrorBoundary>
            <NoWithdrawal />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.transfer_to_account')}>
          <ErrorBoundary>
            <Transfer />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.sell_payment')}>
          <ErrorBoundary>
            <Payment />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.buy')}>
          <ErrorBoundary>
            <BuyCurrency />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.sell')}>
          <ErrorBoundary>
            <SellCurrency />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('accounts.index')}>
          <ErrorBoundary>
            <AccountsIndex />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('history.index')}>
          <ErrorBoundary>
            <HistoryIndex />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('notifications.new_notification')}>
          <ErrorBoundary>
            <NewNotification />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('notifications.index')}>
          <ErrorBoundary>
            <NotificationsIndex />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('instructions.index')}>
          <ErrorBoundary>
            <InstructionsIndex />
          </ErrorBoundary>
        </AuthRoute>
        <AuthRoute exact path={route('instructions.payment')}>
          <ErrorBoundary>
            <InstructionPayment />
          </ErrorBoundary>
        </AuthRoute>
        <AuthRoute exact path={route('instructions.transaction_to_account')}>
          <ErrorBoundary>
            <InstructionTransaction />
          </ErrorBoundary>
        </AuthRoute>
        <AuthRoute exact path={route('instructions.no_withdrawal')}>
          <ErrorBoundary>
            <InstructionNoWithdrawal />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('templates.index')}>
          <ErrorBoundary>
            <TemplatesIndex />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('templates.new_template')}>
          <ErrorBoundary>
            <NewTemplate />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('templates.edit')}>
          <ErrorBoundary>
            <EditTemplate />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('bank_accounts.index')}>
          <ErrorBoundary>
            <BankAccountsIndex />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('bank_accounts.new_account')}>
          <ErrorBoundary>
            <NewBankAccount />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('settings.index')}>
          <ErrorBoundary>
            <SettingsIndex />
          </ErrorBoundary>
        </AuthRoute>

        <AuthRoute exact path={route('videos.index')}>
          <ErrorBoundary>
            <VideosIndex />
          </ErrorBoundary>
        </AuthRoute>

        <Route exact path="*">
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <NotFound />
            </Suspense>
          </ErrorBoundary>
        </Route>
      </Switch>
    </AppLayout>
  );
}

export default App;
