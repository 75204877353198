export const classes = (classString, classes) => {
  const classesList = [];
  for (const classKey in classes) {
    const condition = classes[classKey];
    if (condition) {
      classesList.push(classKey);
    }
  }
  return `${classString}${
    classesList.length && classString ? ' ' : ''
  }${classesList.join(' ')}`;
};
