export const authRoutes = [
  window.preload.routes['auth.forgot'].cs.url,
  window.preload.routes['auth.forgot'].en.url,
  window.preload.routes['auth.login'].cs.url,
  window.preload.routes['auth.login'].en.url,
  window.preload.routes['auth.reset'].cs.url,
  window.preload.routes['auth.reset'].en.url,
  window.preload.routes['auth.restoration'].cs.url,
  window.preload.routes['auth.restoration'].en.url,
];
