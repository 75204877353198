import React from 'react';

function matchesMedia(media) {
  return window.matchMedia(media);
}

function useMediaQuery(media) {
  const [matches, setMatches] = React.useState(
    () => matchesMedia(media).matches,
  );

  React.useEffect(() => {
    const mediaQuery = matchesMedia(media);

    const setMatchesMedia = (e) => {
      setMatches(e.matches);
    };

    setMatches(mediaQuery.matches);

    mediaQuery.addEventListener('change', setMatchesMedia);

    return () => {
      mediaQuery.removeEventListener('change', setMatchesMedia);
    };
  }, [media]);

  return matches;
}

export default useMediaQuery;
