import { internalFetch } from '../api';
import moment from 'moment';

const caches = new Map();

function hash(...rest) {
  return JSON.stringify(rest);
}

function wrapPromise(promise) {
  let status = 'pending';
  let result;
  let suspender = promise.then(
    (r) => {
      status = 'success';
      result = r;
    },
    (e) => {
      status = 'error';
      result = e;
    },
  );
  return {
    cached_at: performance.now(),
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    },
  };
}

export function deleteResource(callback) {
  if (!caches.has(callback)) {
    return;
  }

  caches.get(callback).clear();
}

export function createResource(callback, cacheFor = 600000) {
  if (!caches.has(callback)) {
    caches.set(callback, new Map());
  }

  const callbackCache = caches.get(callback);

  return (...params) => {
    const hashed = hash(...params);

    if (
      callbackCache.has(hashed) &&
      performance.now() - callbackCache.get(hashed).cached_at < cacheFor
    ) {
      return callbackCache.get(hashed);
    }

    const promise = wrapPromise(callback(...params));

    callbackCache.set(hashed, promise);

    return promise;
  };
}

export function balanciesIndex(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'balancies.index',
    null,
    locale,
    token,
    { account_id: accountId, as_at_date: moment().format() },
    null,
  ).then((res) => res.json.data);
}

export function bussinessHoursIndex(locale, token) {
  return internalFetch(
    null,
    'businesshours.index',
    null,
    locale,
    token,
    null,
    null,
  ).then((res) => res.json.data);
}

export function holidaysIndex(locale, token, _, from, to) {
  if (!from || !to) {
    throw new Error();
  }

  return internalFetch(
    null,
    'holidays.index',
    null,
    locale,
    token,
    { date_from: from, date_to: to },
    null,
  ).then((res) => res.json.data);
}

export function ratesIndex(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'rates.index',
    null,
    locale,
    token,
    { account_id: accountId },
    null,
  ).then((res) => res.json.data);
}

export function users(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'users.index',
    null,
    locale,
    token,
    { account_id: accountId },
    null,
  ).then((res) => res.json.data);
}

export function longTermRatesIndex(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'rates.index',
    null,
    locale,
    token,
    { account_id: accountId },
    null,
  ).then((res) => res.json.data);
}

export function bankAccountsIndex(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'accounts.index',
    null,
    locale,
    token,
    { account_id: accountId },
    null,
  ).then((res) => res.json.data);
}

export function allPaymentTempatesIndex(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'allpaymenttemplates.index',
    null,
    locale,
    token,
    { account_id: accountId },
    null,
  ).then((res) => res.json.data);
}

export function paymentTemplatesIndex(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'paymenttemplates.index',
    null,
    locale,
    token,
    { account_id: accountId },
    null,
  ).then((res) => res.json.data);
}

export function ordersIndex(locale, token, accountId, from, to) {
  if (!accountId) {
    throw new Error();
  }

  if (!from || !to) {
    throw new Error();
  }

  return internalFetch(
    null,
    'orders.index',
    null,
    locale,
    token,
    { account_id: accountId, from: from, to: to },
    null,
  ).then((res) => res.json.data);
}

export function alertsIndex(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'alerts.index',
    null,
    locale,
    token,
    { account_id: accountId },
    null,
  ).then((res) => res.json.data);
}

export function movementsIndex(locale, token, accountId, from, to) {
  if (!accountId || !from || !to) {
    throw new Error();
  }

  return internalFetch(
    null,
    'movements.index',
    null,
    locale,
    token,
    { account_id: accountId, date_from: from, date_to: to },
    null,
  ).then((res) => res.json.data);
}

export function tradesIndex(
  locale,
  token,
  accountId,
  from,
  to,
  currency,
  sum,
  recipient,
  iban,
  bankAccount,
) {
  if (!accountId || !from || !to) {
    throw new Error();
  }

  return internalFetch(
    null,
    'trades.index',
    null,
    locale,
    token,
    {
      account_id: accountId,
      created_at_from: from,
      created_at_to: to,
      base_currency: currency,
      amount_base_currency: sum,
      creditor_name: recipient,
      creditor_account_iban: iban,
      creditor_account_no: bankAccount,
      amount_operator: '=',
    },
    null,
  ).then((res) => res.json.data);
}

export function clientCurrenciesIndex(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'clientcurrencies.index',
    null,
    locale,
    token,
    { account_id: accountId },
    null,
  ).then((res) => res.json.data);
}

export function paymentTitlesIndex(locale, token) {
  return internalFetch(
    null,
    'paymenttitles.index',
    null,
    locale,
    token,
    null,
    null,
  ).then((res) => res.json.data);
}

export function paymentPurposesIndex(locale, token) {
  return internalFetch(
    null,
    'paymentpurposes.index',
    null,
    locale,
    token,
    null,
    null,
  ).then((res) => res.json.data);
}

export function tradeValidationMessagesIndex(locale, token) {
  return internalFetch(
    null,
    'tradevalidationmessages.index',
    null,
    locale,
    token,
    null,
    null,
  ).then((res) => res.json.data);
}

export function countriesIndex(locale, token) {
  return internalFetch(
    null,
    'countries.index',
    null,
    locale,
    token,
    null,
    null,
  ).then((res) => res.json.data);
}

export function complaintsStore(
  locale,
  token,
  accountId,
  confirmation_number,
  description,
) {
  if (!accountId || !confirmation_number || !description) {
    throw new Error();
  }

  return internalFetch(
    null,
    'complaints.store',
    null,
    locale,
    token,
    {
      account_id: accountId,
      confirmation_number: confirmation_number,
      description: description,
    },
    null,
  ).then((res) => res.json.data);
}

export function swiftsStore(locale, token, accountId, confirmation_number) {
  if (!accountId || !confirmation_number) {
    throw new Error();
  }

  return internalFetch(
    null,
    'swifts.store',
    null,
    locale,
    token,
    {
      account_id: accountId,
      confirmation_number: confirmation_number,
    },
    null,
  ).then((res) => res.json.data);
}

export function graphShow(locale, token, accountId, currencyPair, days) {
  if (!accountId) {
    throw new Error();
  }

  if (!currencyPair || !days) {
    throw new Error();
  }

  return internalFetch(
    null,
    'graph.show',
    null,
    locale,
    token,
    { account_id: accountId, currency_pair: currencyPair, days: days },
    null,
  ).then((res) => {
    return res.json.data;
  });
}

export function amlMessageIndex(locale, token, accountId) {
  if (!accountId) {
    throw new Error();
  }

  return internalFetch(
    null,
    'amlMessage.store',
    null,
    locale,
    token,
    { account_id: accountId },
    null,
  ).then((res) => res.json.data);
}
