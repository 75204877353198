import React from 'react';
import PropTypes from 'prop-types';

const ERRORS = {
  cs: 'Omlouváme se, došlo k neočekáváné chybě, prosím zkuste stranku znovu načíst, případně kontaktujte technickou podporu na podpora@cyrrus.cz',
  en: 'Sorry, an unexpected error occurred, please try reloading the page or contact technical support at podpora@cyrrus.cz',
};

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return {
      error: error,
    };
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div className="ErrorBoundary">
          {ERRORS[localStorage.getItem('locale') || 'cs']}
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
