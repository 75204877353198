import { API_URL, REQUEST_URLS, X_API_KEY } from './routes';

const ERRORS = {
  cs: {
    500: 'Interní chyba serveru',
    501: 'Není implementováno',
    502: 'Chybná brána',
    503: 'Služba je nedostupná',
    504: 'Časový limit brány vypršel',
    505: 'Verze HTTP není podporována',
    506: 'Variant Also Negotiate',
    507: 'Nedostatečné úložiště',
    508: 'Zjištěna smyčka',
    510: 'Nerozšířeno',
    511: 'Je vyžadováno ověření v síti',
    '5xx': 'Neočekávaná chyba',
  },
  en: {
    500: 'Internal server error',
    501: 'Not implemented',
    502: 'Bad gateway',
    503: 'Service unavailable',
    504: 'Gateway timeout',
    505: 'HTTP version not supported',
    506: 'Variant aso negotiate',
    507: 'Insufficient storage',
    508: 'Loop detected',
    510: 'Not extended',
    511: 'Network authentication required',
    '5xx': 'Unexpected error',
  },
};

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function internalFetch(
  signal,
  url,
  method,
  locale,
  token = null,
  formData = null,
  parameters = null,
  json = true,
) {
  if (method === undefined || method === null) {
    if (!url) {
      throw new Error('Url/Route required');
    }

    if (!(url in REQUEST_URLS) || !REQUEST_URLS[url]) {
      throw new Error('Route does not exists :' + url);
    }

    if (!REQUEST_URLS[url].url || !REQUEST_URLS[url].method) {
      throw new Error('Route declaration attributes missing :' + url);
    }

    method = REQUEST_URLS[url].method;
    url = API_URL + REQUEST_URLS[url].url;
  }

  if (!url || !method || !locale) {
    throw new Error('Arquments missing');
  }

  if (['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].indexOf(method) === -1) {
    throw new Error('Invalid method :' + url);
  }

  const headers = {
    'Accept-Language': locale,
    Accept: 'application/json',
    'X-Api-Key': X_API_KEY,
    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
  };

  let body = null;
  let query = null;

  if (formData) {
    if (method === 'GET') {
      query = new URLSearchParams(formData);
    } else {
      if (formData instanceof FormData) {
        body = formData;
      } else {
        body = JSON.stringify(formData);

        headers['Content-Type'] = 'application/json';
      }
    }
  }

  if (token) {
    // headers.Authorization = `Bearer ${token}`;
  }

  if (parameters) {
    if (typeof parameters !== 'object') {
      throw new Error('Parameters has to object');
    }

    Object.keys(parameters).forEach((key) => {
      url = url.replace(`:${key}`, String(parameters[key]));
    });
  }

  return fetch(url + (query ? '?' + query.toString() : ''), {
    method,
    headers,
    body,
    signal,
    credentials: 'include',
  }).then(
    (response) => {
      const status = {
        ok: response.ok,
        status: response.status,
        text: response.statusText,
      };

      if (status.status >= 500) {
        alert(
          ERRORS[localStorage.getItem('locale') || 'cs'][status.status] ||
            ERRORS[localStorage.getItem('locale') || 'cs']['5xx'],
        );
      }

      if (json) {
        return response.json().then(
          (json) => {
            return { status, json, blob: null };
          },
          () => {
            return { status, json: null, blob: null };
          },
        );
      } else {
        return response.blob().then(
          (blob) => {
            return { status, json: null, blob: blob };
          },
          () => {
            return { status, json: null, blob: null };
          },
        );
      }
    },
    (error) => {
      console.error(error);
      return error;
    },
  );
}

export default function request(
  signal,
  url,
  method,
  locale,
  token = null,
  formData = null,
  parameters = null,
  json = true,
) {
  if (!signal) {
    throw new Error('Signal is missing');
  }

  return internalFetch(
    signal,
    url,
    method,
    locale,
    token,
    formData,
    parameters,
    json,
  );
}
