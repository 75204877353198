import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Loading from '../routes/Loading';

import CloseIcon from '../icons/CloseIcon';

function Modal({ hide, children, isShowing, wide, noPadding }) {
  function hideModal() {
    hide(false);
  }

  return (
    isShowing &&
    ReactDOM.createPortal(
      <div aria-hidden="true" className="Modal" onClick={() => hideModal()}>
        <div
          aria-hidden="true"
          className={`Modal__body ${wide ? 'Modal__body--wide' : ''} ${
            noPadding ? 'Modal__body--no-padding' : ''
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <button className="Modal__close-button" onClick={hide} type="button">
            <span className="Modal__close-icon">
              <CloseIcon />
            </span>
          </button>

          <Suspense fallback={<Loading />}>{children}</Suspense>
        </div>
      </div>,
      document.body,
    )
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  hide: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  noPadding: PropTypes.bool,
  wide: PropTypes.bool,
};

Modal.defaultProps = {
  noPadding: false,
  wide: false,
};

export default Modal;
