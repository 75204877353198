import React from 'react';
import PropTypes from 'prop-types';

import useTrans from '../hooks/useTrans';

function Loading({ classNames, styles, label }) {
  const { trans } = useTrans();

  return (
    <div
      className={['Loading', ...classNames].filter((el) => el).join(' ')}
      style={{ ...styles }}
    >
      <p>{label || trans('navigation.loading_label')}</p>

      <div
        aria-label={trans('navigation.loading')}
        className="mdc-linear-progress mdc-linear-progress--indeterminate"
        role="progressbar"
      >
        <div className="mdc-linear-progress__buffer">
          <div className="mdc-linear-progress__buffer-bar" />

          <div className="mdc-linear-progress__buffer-dots" />
        </div>

        <div className="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
          <span className="mdc-linear-progress__bar-inner" />
        </div>

        <div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
          <span className="mdc-linear-progress__bar-inner" />
        </div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  styles: PropTypes.shape(),
};

Loading.defaultProps = {
  classNames: [],
  label: '',
  styles: {},
};

export default Loading;
