import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import useLocale from '../hooks/useLocale';

export const TransContext = createContext();

function TransContextProvider({ children }) {
  const [translations] = useState(window.preload.trans);

  const { locale } = useLocale();

  const trans = useCallback(
    (toTranslate) => {
      let searchable = toTranslate.split('.');

      let searching = translations[locale];

      if (!searching) {
        throw new Error('Locale translations not found :' + locale);
      }

      for (let i = 0; i < searchable.length; i++) {
        const search = searchable[i];

        if (!searching[search]) {
          return toTranslate;
        }

        searching = searching[search];
      }

      if (typeof searching !== 'string') {
        throw new Error('Trans is not final :' + toTranslate);
      }

      return searching;
    },
    [translations, locale],
  );

  return (
    <TransContext.Provider value={{ trans, locale }}>
      {children}
    </TransContext.Provider>
  );
}

TransContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

TransContextProvider.defaultProps = {};

export default TransContextProvider;
