import React, { useCallback, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import Loading from './Loading';

import useAuth from '../hooks/useAuth';
import useRoutes from '../hooks/useRoutes';

function AuthRoute({ children, ...rest }) {
  const { route } = useRoutes();

  const { token, account } = useAuth();

  const render = useCallback(
    ({ location }) => {
      if (token && !account) {
        return <Loading />;
      }

      if (token) {
        return <Suspense fallback={<Loading />}>{children}</Suspense>;
      }

      location.state = location.state || {};

      return (
        <Redirect
          to={{
            pathname: location.state.intended_pathname || route('auth.login'),
            search: location.state.intended_search,
            hash: location.state.intended_hash,
            state: {
              intended_pathname: location.pathname,
              intended_search: location.search,
              intended_hash: location.hash,
            },
          }}
        />
      );
    },
    [token, children, route, account],
  );

  /* eslint-disable react/jsx-props-no-spreading */
  return <Route {...rest} render={render} />;
  /* eslint-enable react/jsx-props-no-spreading */
}

AuthRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthRoute.defaultProps = {};

export default AuthRoute;
