import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { classes } from '../utils/helpers';
import UserIcon2 from '../icons/UserIcon';
import ArrowIcon from '../icons/ArrowIcon';

function UserIcon({ name, ...props }) {
  const { className, children, trailing } = props;

  const [open, setOpen] = useState(false);

  return (
    <div className={classes('UserIcon', { [className]: className })}>
      <div
        className="UserIcon__inner"
        onClick={() => {
          setOpen(!open);
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
      >
        <UserIcon2 />
        <span className="UserIcon__username">{name}</span>

        {trailing ? (
          <ArrowIcon className="UserIcon__arrow Icon--on-primary" />
        ) : null}
      </div>

      {open ? children : null}
    </div>
  );
}

UserIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  trailing: PropTypes.bool,
};

UserIcon.defaultProps = {
  children: null,
  className: '',
  trailing: false,
};

export default UserIcon;
