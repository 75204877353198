import React from 'react';
import PropTypes from 'prop-types';

function ArrowIcon({ className }) {
  return (
    <svg
      className={className}
      height="9.951"
      viewBox="0 0 5.672 9.951"
      width="5.672"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ic-arrow-down" transform="translate(6)">
        <g
          data-name="Component 28 – 4"
          id="Component_28_4"
          transform="translate(-6)"
        >
          <path
            d="M5.468,5.468,1.19,9.746A.7.7,0,0,1,.2,8.761L3.99,4.975.2,1.19A.7.7,0,0,1,1.19.2L5.468,4.483a.7.7,0,0,1,0,.985Z"
            data-name="Path 2935"
            id="Path_2935"
          />
        </g>
      </g>
    </svg>
  );
}

ArrowIcon.propTypes = {
  className: PropTypes.string,
};
ArrowIcon.defaultProps = {
  className: '',
};

export default ArrowIcon;
