import React from 'react';
import PropTypes from 'prop-types';

import useFetch from '../hooks/useFetch';
import useAuth from '../hooks/useAuth';
import useTrans from '../hooks/useTrans';

import phone from '../../assets/svg/phone-orange.svg';

import { createResource, users } from '../fetch';

const accountsResource = createResource(users);

function ContactItem({ label, hide }) {
  const { token, account } = useAuth();
  const { trans } = useTrans();

  const accountsFetch = useFetch(accountsResource);

  if (!token || !account) {
    return null;
  }

  let user = accountsFetch().read();

  if (!user || Array.isArray(user)) {
    return null;
  }

  return (
    <div className="contact-item" style={{ textAlign: 'center' }}>
      {window.preload.menu_image ? (
        <a
          href={window.preload.menu_image.href}
          onClick={hide}
          rel="noopener noreferrer"
          style={{ display: 'block' }}
          target="_blank"
        >
          <img
            alt=""
            src={window.preload.menu_image.src}
            style={{
              maxWidth: '100%',
              height: 'auto',
              display: 'block',
              margin: '0 auto',
            }}
          />
        </a>
      ) : null}

      {user ? (
        <>
          <span className="contact-item__label">{label}</span>

          {user.url_photo ? (
            <img
              alt={user.user_name}
              className="contact-item__user-image"
              src={user.url_photo}
            />
          ) : null}

          {user.user_name ? (
            <div className="contact-item__phone-wrapper">{user.user_name}</div>
          ) : null}

          <div className="contact-item__phone-wrapper">
            <span className="contact-item__phone-number">
              {trans('8:30 - 17:00')}
            </span>
          </div>

          {user.phone ? (
            <div className="contact-item__phone-wrapper">
              <span aria-hidden="true" className="contact-item__icon">
                <img alt={label} src={phone} />
              </span>

              <a
                className="contact-item__phone-number"
                href={'tel:' + user.phone}
              >
                {user.phone}
              </a>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

ContactItem.propTypes = {
  hide: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default ContactItem;
