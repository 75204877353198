import React from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import button from '../../assets/svg/play-button.svg';
import useRoutes from '../hooks/useRoutes';
import useTrans from '../hooks/useTrans';

function VideosButton() {
  const { route } = useRoutes();
  const { trans } = useTrans();

  return (
    <div className="video-button">
      <NavLink to={route('videos.index')}>
        <img alt="video" src={button} />
        <span>{trans('header.videos.video_tutorials')}</span>
      </NavLink>
    </div>
  );
}

export default VideosButton;
