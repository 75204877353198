import React from 'react';

import PropTypes from 'prop-types';
import parse from 'html-react-parser';
function AmlMessage({ message }) {
  const textToHtml = (input) => {
    const brReplaced = input.replace(/\n/g, ' <br /> ');

    const urlPattern = /((https)[^\s\\]+)/g;

    const linkReplaced = brReplaced.replace(urlPattern, function (url) {
      return `<a href="${url}" target='_blank' className="Modal--amlMessage__link">${url.slice(8)}</a>`;
    });
    return linkReplaced;
  };
  const stringMessage = textToHtml(message);
  const htmlMessage = parse(stringMessage);

  return (
    <div className="Modal--amlMessage">
      <p>{htmlMessage}</p>
    </div>
  );
}
AmlMessage.propTypes = {
  message: PropTypes.node.isRequired,
};

export default AmlMessage;
