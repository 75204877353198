import React from 'react';
import PropTypes from 'prop-types';

function ToastRegion({ children }) {
  return (
    <div className="ToastRegion">
      <div className="ToastRegion__body">{children}</div>
    </div>
  );
}

ToastRegion.propTypes = {
  children: PropTypes.node,
};

ToastRegion.defaultProps = {
  children: null,
};

export default ToastRegion;
