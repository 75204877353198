import React from 'react';
import PropTypes from 'prop-types';

function CloseIcon({ className }) {
  return (
    <svg
      className={className}
      height="12.908"
      viewBox="0 0 12.908 12.908"
      width="12.908"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.408,8.8l-1.3-1.3-5.154,5.154L8.8,7.5,7.5,8.8l5.154,5.154L7.5,19.108l1.3,1.3,5.154-5.154,5.154,5.154,1.3-1.3-5.154-5.154Z"
        transform="translate(-7.5 -7.5)"
      />
    </svg>
  );
}

CloseIcon.propTypes = {
  className: PropTypes.string,
};
CloseIcon.defaultProps = {
  className: 'Icon--on-primary',
};

export default CloseIcon;
