import React from 'react';
import PropTypes from 'prop-types';

function UserIcon({ className }) {
  return (
    <svg
      className={className}
      height="19.232"
      viewBox="0 0 19.156 19.232"
      width="19.156"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M307.182,443.412a9.614,9.614,0,0,0-6.427-8.273,5.558,5.558,0,1,0-6.29,0,9.628,9.628,0,0,0-6.427,8.222l0,.015c0,.017,0,.032,0,.048a.758.758,0,0,0,.706.8.73.73,0,0,0,.8-.677v-.014a8.113,8.113,0,0,1,16.156,0,.749.749,0,0,0,1.5-.059A.643.643,0,0,0,307.182,443.412Zm-9.571-8.8a4.056,4.056,0,1,1,4.056-4.056A4.056,4.056,0,0,1,297.611,434.614Z"
        transform="translate(-288.028 -424.996)"
      />
    </svg>
  );
}

UserIcon.propTypes = {
  className: PropTypes.string,
};
UserIcon.defaultProps = {
  className: 'Icon--primary',
};

export default UserIcon;
