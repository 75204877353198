import React from 'react';

import useTrans from '../hooks/useTrans';

function Footer() {
  const { trans } = useTrans();
  const d = new Date();
  const year = d.getFullYear();

  return (
    <footer className="Footer">
      <div className="Footer__cell Footer__version-number">
        {trans('footer.version')}
      </div>

      <a
        className="Footer__cell Footer__link"
        href={trans('footer.link.href')}
        rel="noopener noreferrer"
        target="_blank"
      >
        {trans('footer.link.label')}
      </a>

      <div className="Footer__cell Footer__copyright">
        {trans('footer.copyright') + year}
      </div>
    </footer>
  );
}

export default Footer;
