import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import AsideItem from '../atoms/AsideItem';
import ContactItem from '../atoms/ContactItem';

import useTrans from '../hooks/useTrans';
import useRoutes from '../hooks/useRoutes';

//import homeIcon from '../../assets/svg/home.svg';
import homeIconOrange from '../../assets/svg/home-orange.svg';
//import history from '../../assets/svg/historie.svg';
import historyOrange from '../../assets/svg/historie-orange.svg';
//import alarmClock from '../../assets/svg/alarm-clock.svg';
import alarmClockOrange from '../../assets/svg/alarm-clock-orange.svg';
//import book from '../../assets/svg/Book.svg';
import bookOrange from '../../assets/svg/Book-orange.svg';
//import note from '../../assets/svg/paper-note.svg';
import noteOrange from '../../assets/svg/paper-note-orange.svg';
//import walletZipper from '../../assets/svg/wallet-with-zipper.svg';
import walletZipperOrange from '../../assets/svg/wallet-with-zipper-orange.svg';
//import cogwheel from '../../assets/svg/feather.svg';
import cogwheelOrange from '../../assets/svg/feather-orange.svg';

function Aside({ hidden, isMobileExpanded, hide }) {
  const { trans } = useTrans();
  const { route } = useRoutes();

  return (
    <>
      {isMobileExpanded ? <div className="overlay" /> : null}

      <aside
        className={[
          'drawer',
          'Aside',
          !hidden && 'Aside--visible',
          isMobileExpanded && 'Aside--expanded',
        ]
          .filter((el) => el)
          .join(' ')}
        hidden={hidden}
      >
        <div className="drawer__content">
          <nav className="list">
            <AsideItem
              // vyřešit light u svg
              icon={homeIconOrange}
              iconActive={homeIconOrange}
              label={trans('aside.accounts')}
              onClick={hide}
              to={route('accounts.index')}
            />

            <AsideItem
              icon={historyOrange}
              iconActive={historyOrange}
              label={trans('aside.history')}
              onClick={hide}
              to={route('history.index')}
            />

            <AsideItem
              icon={alarmClockOrange}
              iconActive={alarmClockOrange}
              label={trans('aside.notifications')}
              onClick={hide}
              to={route('notifications.index')}
            />

            <AsideItem
              icon={bookOrange}
              iconActive={bookOrange}
              label={trans('aside.instructions')}
              onClick={hide}
              to={route('instructions.index')}
            />

            <AsideItem
              icon={noteOrange}
              iconActive={noteOrange}
              label={trans('aside.templates')}
              onClick={hide}
              to={route('templates.index')}
            />

            <AsideItem
              icon={walletZipperOrange}
              iconActive={walletZipperOrange}
              label={trans('aside.bank_accounts')}
              onClick={hide}
              to={route('bank_accounts.index')}
            />

            <AsideItem
              icon={cogwheelOrange}
              iconActive={cogwheelOrange}
              label={trans('aside.settings')}
              onClick={hide}
              to={route('settings.index')}
            />

            <Suspense fallback={<div />}>
              <ContactItem
                hide={hide}
                label={trans('contact.contactus')}
                number={trans('contact.number')}
              />
            </Suspense>
          </nav>
        </div>
      </aside>
    </>
  );
}

Aside.propTypes = {
  hidden: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  isMobileExpanded: PropTypes.bool.isRequired,
};

Aside.defaultProps = {};

export default Aside;
