import { useEffect, useRef, useCallback, useReducer, useState } from 'react';

import request from '../api';

import useLocale from './useLocale';
import useAuth from './useAuth';

const SET_ERRORS = Symbol();
const INIT = Symbol();

function init() {
  return {};
}

function reducer(_, action) {
  switch (action.type) {
    case SET_ERRORS:
      return { ...action.data };
    case INIT:
      return init();
    default:
      throw new Error();
  }
}

function useApi() {
  const abortController = useRef();

  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useReducer(reducer, null, init);

  const { locale } = useLocale();

  const { token, logout } = useAuth();

  const errorsFor = useCallback(
    (key) => {
      return state.errors ? state.errors[key] || [] : [];
    },
    [state.errors],
  );

  const callApi = useCallback(
    (route, method = null, data = null, params = null, json = true) => {
      if (abortController.current) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      setLoading(true);

      return request(
        abortController.current.signal,
        route,
        method,
        locale,
        token,
        data,
        params,
        json,
      ).then(
        (response) => {
          setLoading(false);

          if (response.status.status === 503) {
            window.location.reload();
          }

          if (response.status.status === 422) {
            dispatch({ type: SET_ERRORS, data: response.json });
          } else {
            dispatch({ type: INIT });
          }

          if (
            (response.status.status === 401 ||
              response.status.status === 450) &&
            token
          ) {
            logout();
          }

          if (response.json && response.json.code !== undefined) {
            if (
              !(
                Number(response.json.code) >= 200 &&
                Number(response.json.code) < 300
              )
            ) {
              return Promise.reject(response);
            }
          }

          if (response.status.ok) {
            return Promise.resolve(response);
          }

          return Promise.reject(response);
        },
        (error) => {
          setLoading(false);

          throw error;
        },
      );
    },
    [dispatch, locale, token, logout, setLoading],
  );

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return { request: callApi, errorsFor, loading };
}

export default useApi;
