import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';

import LocaleContextProvider from './contexts/LocaleContext';
import RouteContextProvider from './contexts/RouteContext';
import TransContextProvider from './contexts/TransContext';
import AuthContextProvider from './contexts/AuthContext';
import StaticContextProvider from './contexts/StaticContext';

import ErrorBoundary from './routes/ErrorBoundary';

import App from './App';

import '../sass/index.scss';
import ToastContextProvider from './contexts/ToastContext';
import ThemeContextProvider from './contexts/ThemeContext';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <LocaleContextProvider>
          <TransContextProvider>
            <RouteContextProvider>
              <AuthContextProvider>
                <StaticContextProvider>
                  <ThemeContextProvider>
                    <App />
                    <ToastContextProvider />
                  </ThemeContextProvider>
                </StaticContextProvider>
              </AuthContextProvider>
            </RouteContextProvider>
          </TransContextProvider>
        </LocaleContextProvider>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
