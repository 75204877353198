import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const LocaleContext = createContext();

function LocaleContextProvider({ children }) {
  const [language, setLanguage] = useState(
    localStorage.getItem('locale') || window.preload.locale,
  );

  const [languages] = useState(window.preload.locales);

  const setLocale = useCallback(
    (locale) => {
      if (languages.indexOf(locale) === -1) {
        throw new Error('Invalid locale :' + locale);
      }

      localStorage.setItem('locale', locale);

      setLanguage(locale);
      localStorage.setItem('isLocaleChanged', true);
    },
    [setLanguage, languages],
  );

  return (
    <LocaleContext.Provider
      value={{ locale: language, setLocale, locales: languages }}
    >
      {children}
    </LocaleContext.Provider>
  );
}

LocaleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

LocaleContextProvider.defaultProps = {};

export default LocaleContextProvider;
