export const API_URL = '/api/v1';

export const X_API_KEY = 'CYRRUSFX';

export const REQUEST_URLS = {
  // guest
  'auth.verify': { method: 'POST', url: '/verifylogin' },

  // authed
  'auth.accesslist': { method: 'GET', url: '/accesslist' },
  'auth.changepassword': { method: 'POST', url: '/changepassword' },

  'auth.requestpassword': { method: 'POST', url: '/requestpassword' },
  'auth.newpassword': { method: 'POST', url: '/newpassword' },

  'auth.accountlanguage': { method: 'POST', url: '/account-language' },

  'clientcurrencies.index': { method: 'POST', url: '/clientcurrencies' },

  'clients.show': { method: 'POST', url: '/clientdetail' },

  'tradevalidationmessages.index': {
    method: 'GET',
    url: '/tradevalidmessages',
  },

  'users.index': { method: 'POST', url: '/users' },
  'accounts.index': { method: 'POST', url: '/traderbankaccounts' },
  'deposits.index': { method: 'POST', url: '/deposits' },
  'payments.index': { method: 'POST', url: '/payments' },
  'movements.index': { method: 'POST', url: '/movements' },
  'trades.index': { method: 'POST', url: '/trades' },
  'balancies.index': { method: 'POST', url: '/balancies' },
  'fxtrades.index': { method: 'POST', url: '/fxtrades' },
  'paymenttemplates.index': { method: 'POST', url: '/paymenttemplates' },
  'allpaymenttemplates.index': { method: 'POST', url: '/allpaymenttemplates' },
  'paymenttemplates.update': { method: 'POST', url: '/updatepaymenttemplate' },
  'rates.index': { method: 'POST', url: '/rates' },
  'orders.index': { method: 'POST', url: '/orders' },
  'alerts.index': { method: 'POST', url: '/alerts' },

  'paymentpurposes.index': { method: 'GET', url: '/paymentpurposes' },
  'paymenttitles.index': { method: 'GET', url: '/paymenttitles' },
  'countries.index': { method: 'GET', url: '/countries' },
  'holidays.index': { method: 'POST', url: '/holidays' },
  'paymentvalidmessages.index': { method: 'GET', url: '/paymentvalidmessages' },
  'tradevalidmessages.index': { method: 'GET', url: '/tradevalidmessages' },
  'businesshours.index': { method: 'GET', url: '/businesshours' },

  'deposits.store': { method: 'POST', url: '/createdeposit' },
  'paymenttemplates.store': { method: 'POST', url: '/createpaymenttemplate' },
  'payments.store': { method: 'POST', url: '/createpayment' },
  'fxs.store': { method: 'POST', url: '/createfx' },
  'alerts.store': { method: 'POST', url: '/createalert' },
  'orders.store': { method: 'POST', url: '/createorder' },

  'alerts.destroy': { method: 'POST', url: '/deletealert' },
  'orders.destroy': { method: 'POST', url: '/deleteorder' },

  'history.export': { method: 'POST', url: '/exportclienttrades' },

  'complaints.store': { method: 'POST', url: '/createcomplaint' },

  'swifts.store': { method: 'POST', url: '/requestswiftmessage' },

  'changerequests.download': {
    method: 'POST',
    url: '/changerequests/download',
  },

  'changerequests.store': {
    method: 'POST',
    url: '/changerequests',
  },

  'graph.show': {
    method: 'POST',
    url: '/graph',
  },
  'bankaccount.store': { method: 'POST', url: '/createbankaccount' },
  'bankaccount.updatevalidity': {
    method: 'POST',
    url: '/updatebankaccountortemplatevalidity',
  },
  'amlMessage.store': { method: 'POST', url: '/aml-message' },
  'valuta.index': { method: 'GET', url: '/valuta' },
};
