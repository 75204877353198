import React, { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { authRoutes } from '../helpers/routes';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const ThemeContext = createContext();

function ThemeContextProvider({ children }) {
  const location = useLocation();

  const [theme, setTheme] = useState(
    localStorage.getItem('ib-theme') || 'dark',
  );
  const changeTheme = useCallback(
    (themeType) => {
      setTheme(themeType);
      localStorage.setItem('ib-theme', themeType);
      document.querySelector('body').className = `${themeType}-theme`;
    },
    [setTheme],
  );

  useEffect(() => {
    const ibTheme = localStorage.getItem('ib-theme') || 'dark';
    if (authRoutes.includes(location.pathname) && theme !== 'light') {
      setTheme('light');
    } else if (!authRoutes.includes(location.pathname) && ibTheme !== theme) {
      setTheme(ibTheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, theme]);

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ThemeContextProvider.defaultProps = {};

export default ThemeContextProvider;
