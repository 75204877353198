import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../hooks/useAuth';
import useApi from '../hooks/useApi';
// import useOutsideClick from '../hooks/useOutsideClick';

import Header from '../molecules/Header';
import Aside from '../molecules/Aside';
import Footer from '../molecules/Footer';
import Loading from '../routes/Loading';
import useMediaQuery from '../hooks/useMediaQuery';
import useLocale from '../hooks/useLocale';
import Modal from '../atoms/Modal';
import AmlMessage from '../atoms/AmlMessage';
import { internalFetch } from '../api';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { authRoutes } from '../helpers/routes';

// TODO add mobile layout
// TODO add tablet layout
function AppLayout({ children }) {
  const {
    token,
    account,
    setAccount,
    setAccounts,
    client,
    accountId,
    freshLogin,
    setFreshLogin,
  } = useAuth();

  const { request, loading } = useApi();
  const { locale } = useLocale();
  const location = useLocation();

  useEffect(() => {
    const bodyClass = document.querySelector('body').className;
    const theme = localStorage.getItem('ib-theme') || 'dark';
    if (authRoutes.includes(location.pathname) && bodyClass !== 'light-theme') {
      document.querySelector('body').className = 'light-theme';
    } else if (
      !authRoutes.includes(location.pathname) &&
      bodyClass !== `${theme}-theme`
    ) {
      document.querySelector('body').className = `${theme}-theme`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const showFooter = !authRoutes.includes(location.pathname);

  const matchesDesktop = useMediaQuery('(min-width: 992px)');

  const [mobileExpanded, setMobileExpanded] = useState(false);

  useEffect(() => {
    if (matchesDesktop && mobileExpanded) {
      setMobileExpanded(false);
    }
  }, [matchesDesktop, mobileExpanded]);

  const ref = useRef();

  const handleKeyboard = (e) => {
    if (e.keycode === '13') {
      setMobileExpanded(!mobileExpanded);
    }
  };

  const toggleMenu = () => {
    setMobileExpanded(!mobileExpanded);
  };

  const hide = () => {
    setMobileExpanded(false);
  };

  useEffect(() => {
    if (token) {
      request('auth.accesslist', null).then(
        (response) => {
          setAccounts(response.json.data);
          setAccount((response.json.data || [])[0] || null);
        },
        () => {},
      );
    }
  }, [token, request, setAccounts, setAccount]);

  useEffect(() => {
    const isLocaleChanged =
      localStorage.getItem('isLocaleChanged') === 'true' || false;
    if (!!isLocaleChanged && !!client && !!accountId && !!locale) {
      localStorage.setItem('isLocaleChanged', false);
      try {
        request('auth.accountlanguage', null, {
          account_id: accountId,
          client_login: client,
          language: locale,
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [client, accountId, locale, request]);

  const [isShowingAmlMessage, setIsShowingAmlMessage] = useState(false);
  const [amlMessageResponse, setAmlMessageResponse] = useState(false);

  const closeAmlMessage = () => {
    setFreshLogin(false);
    setIsShowingAmlMessage(false);
  };
  useEffect(() => {
    if (freshLogin && account) {
      try {
        internalFetch(
          null,
          'amlMessage.store',
          null,
          locale,
          token,
          { account_id: accountId },
          null,
        )
          .then((res) => res.json.data)
          .then((res) => {
            const [{ aml_message }] = res;
            if (aml_message) {
              setAmlMessageResponse(aml_message);
              setIsShowingAmlMessage(true);
            }
            setFreshLogin(false);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [freshLogin, account, request, locale, token, accountId, setFreshLogin]);
  /* client detail
  useEffect(() => {
    if (token && account) {
      request('clients.show', null, { account_id: account.account_id }).then(
        (response) => {
          console.log('response: ', response);
        },
        () => {},
      );
    }
  }, [token, account, request]);
  */

  // This breaks things on mobile ( can't navigate side menu)
  //useOutsideClick(ref, () => setMobileExpanded(false));
  if (!account && loading) {
    return <Loading />;
  }

  return (
    <div className="AppLayout">
      <Header hidden={!token ? true : false} />

      {token ? (
        <div
          className={`AppLayout__menu-toggler ${
            mobileExpanded ? 'AppLayout__menu-toggler--expanded' : ''
          }`}
          onClick={() => toggleMenu()}
          onKeyDown={(e) => handleKeyboard(e)}
          ref={ref}
          role="button"
          tabIndex={0}
        >
          <div className="AppLayout__toggler-bar" />

          <div className="AppLayout__toggler-bar" />

          <div className="AppLayout__toggler-bar" />
        </div>
      ) : null}

      <div
        className={[
          'AppLayout__content',
          (!token || mobileExpanded) && 'AppLayout__content--hidden',
        ]
          .filter((el) => el)
          .join(' ')}
      >
        <Aside
          hidden={!token ? true : false}
          hide={hide}
          isMobileExpanded={mobileExpanded}
        />

        <div style={{ overflow: 'auto' }}>
          <main
            className={`AppLayout__container ${!token ? 'fullscreen' : ''} ${
              mobileExpanded ? 'collapsed' : ''
            }`}
          >
            {children}
            {isShowingAmlMessage && (
              <Modal hide={closeAmlMessage} isShowing={isShowingAmlMessage}>
                <AmlMessage message={amlMessageResponse} />
              </Modal>
            )}
          </main>
        </div>

        {showFooter && <Footer />}
      </div>
    </div>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

AppLayout.defaultProps = {};

export default AppLayout;
